<template>
  <div id="menubody">
    <div class="showbody">
      <ul class="showul">
        <span v-if="totalnum!=0?false:true"
        class="showulspan"
        >{{$t('m.reservation.No_data_found')}}!!</span>
        <li class="showli"
         v-for="(item,index) in yushouList" :key="index">
          <div class="yscont"
          @click="toDetails(item)">
            <span class="yushouCont">{{$t('m.reservation.Pre_sale')}}</span>
            <img :src="item.pic" alt="">
            <p class="p1 ptitle">
              {{item.name}}
            </p>
            <p class="p1">
              <span>
                  <i class="zititi c1">￥</i>{{item.price}}
                  <i class="zititi">/{{item.unit}}</i>
              </span>
            </p>
            <!-- <p class="zititi">转售数：<i class="p2">{{item.number}}</i></p> -->
            <p class="zititi">{{$t('m.reservation.Deadline')}}：<i class="p2">{{item.offerExpiryDate}}</i></p>
          </div>
        </li>
      </ul>
    </div>
    <div class="showfooter" v-if="totalnum<pagesize?false:true">
      <el-pagination
        v-model:currentPage="currentPage"
        :page-size="pagesize"
        layout="prev, pager, next, jumper"
        :total="totalnum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, ref } from 'vue'
import emitter from '../../tools/bus'
// import { useRouter } from 'vue-router'

export default {
  name: 'MenuBody',
  data () {
    return {
      // 存放总数据个数
      totalnum: ref(0),
      // 存放一页显示个数
      pagesize: ref(16),
      // 默认展示页
      currentPage: ref(1),
      yushouList: reactive([])// 展示数据
    }
  },
  methods: {
    toDetails (data) {
      // console.log(data)
      // this.$emit('dataToDetail', data)
      this.$router.push({
        path: '/reservation/varietiy_details',
        query: {
          productSn: data.productSn
        }
      })
    }
  },
  setup (props, ctx) {
    const datacop = getCurrentInstance()
    emitter.on('preSaleList', (data) => {
      // console.log(data)
      // 页数赋值
      datacop.data.totalnum = data.total
      datacop.data.yushouList = data.list
      // console.log(datacop.data.yushouList)
    })
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    const handleCurrentChange = (val) => {
      // console.log(`current page: ${val}`)
      emitter.emit('toPageNum', val)
    }
    return {
      handleSizeChange,
      handleCurrentChange
    }
  }
}
</script>

<style lang="scss">
@import '../../style/viriables.scss';
#menubody{
  margin-top: 10px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-flow: column;
  .showbody{
    width: 100%;
    flex: 1;
    .showul{
      min-height: 100px;
      // background: $divbgColor;
      .showulspan{
        display: inline-block;
        width: 100%;
        color: #AC703B;
        line-height: 100px;
        font-size: 30px;
        text-align: center;
      }
      .showli{
        float: left;
        width: 300px;
        box-sizing: border-box;
        padding: 20px;
        background: $divbgColor;
        border-right: 1px solid $bgColor;
        border-bottom: 1px solid $bgColor;
        .yscont{
          position: relative;
          .yushouCont{
            min-width: 30px;
            padding: 0 5px;
            height: 20px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
            background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
            border-radius: 0px 0px 4px 0px;
            position: absolute;
          }
          p{
            line-height: 35px;
          }
          img{
            width: 260px;
            height: 260px;
          }
          .p1{
            color: #333333;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            width: 260px;
            span{
              display: inline-block;
              // float: right;
              text-align: right;
              color: #c88756;
              font-size: 20px;
              .c1{
                color: #c88756;
              }
            }
          }
          .ptitle{
            line-height: 21px;
            margin: 10px 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .zititi{
            color: #999999;
            font-size: 14px;
            .p2{
              color: #333333;
            }
          }
        }
      }
    }
  }
  .showfooter{
    height: 100px;
    // background: red;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .el-pagination{
      // line-height: 100px;
      .el-pager{
        margin: 0 20px;
        .number,.btn-quicknext{
          margin: 0 5px;
          width: 40px;
          height: 40px;
          border: 1px solid transparent;
          line-height: 40px;
        }
        .active{
          background: #ffffff;
          color: #C6824E;
          border-color: #C6824E;
        }
        .number:hover{
          color: #C6824E;
        }
        .el-icon{
          height: 40px;
          line-height: 40px;
        }
      }
      .btn-prev,.btn-next,.el-pagination__jump{
        height: 40px;
        line-height: 40px;
        .el-input{
          height: 40px;
          .el-input__inner{
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
